<template>
    <div class="companies">
        <MainHeader
            v-if="responseCompanies.companies"
            class="companies__header"
            title="Компании"
            :number="responseCompanies.companies.length ? numberCompanies : ''"
            btnText="Новая компания"
            @btnClick="$router.push('/companies/new')"
        />
        <div class="companies__row">
            <VSegmentedControl
                :items="filteringOptions"
                :modelValue="activeFilter"
                @update:modelValue="filtration"
            />
            <VDefaultInput
                v-model="search"
                @update:modelValue="companySearch"
                placeholder="Введите название компании"
                border
                class="companies__search"
            >
                <template #iconLeft>
                    <img src="@/assets/images/icons/search.svg" alt="search"/>
                </template>
                <template #iconRight>
                    <div
                        class="companies__search-clear"
                        v-if="search"
                        @click="clearSearch"
                    >
                        <img src="@/assets/images/icons/x.svg" alt="search"/>
                    </div>
                </template>
            </VDefaultInput>
        </div>

        <div class="companies__no-content" v-if="errorGet">
            <VNoContent
                title="Ошибка"
                description="Произошла ошибка. Пожалуйста, попробуйте позже"
            />
        </div>

        <div class="companies__content" v-if="!errorGet && responseCompanies.companies">
            <div
                class="companies__companies"
                v-if="responseCompanies.companies.length"
            >
                <CompanyCard
                    class="companies__company"
                    v-for="company in responseCompanies.companies"
                    :key="company.id"
                    :company="company"
                />
            </div>
            <div class="companies__no-content" v-if="!responseCompanies.companies.length">
                <VNoContent
                    v-if="!search"
                    title="Список компаний пуст"
                    description="По выбранному фильтру ничего не найдено"
                />
                <VNoContent
                    v-if="search"
                    title="Ничего не найдено"
                    description="Проверьте пожалуйста, правильно ли вы написали название"
                />
            </div>

            <div
                class="companies__pagination"
                v-if="responseCompanies.pages_count > 1"
            >
                <VButton
                    class="companies__pagination-btn"
                    label="Загрузить еще"
                    bg="#E4E7EE"
                    color="#0D5FCB"
                    bgHover="#EDF4FC"
                    colorHover="#2C72FF"
                    @click="loadMore()"
                    v-if="page < responseCompanies.pages_count && showPagination"
                />
                <VPaginate
                    :pageCount="responseCompanies.pages_count"
                    :modelValue="page"
                    @update:modelValue="pagination"
                    class="companies__pagination-pag"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted, watch, reactive} from "vue";
import companies from "@/api/companies";
import MainHeader from "@/components/MainHeader";
import CompanyCard from "@/components/CompanyCard";
import VPaginate from "@/components/UI/VPaginate";

const filteringOptions = [
    {
        id: "all",
        label: "Все",
    },
    {
        id: "1",
        label: "Активные",
    },
    {
        id: "0",
        label: "Заблокированные",
    },
];
const activeFilter = ref("all");
const search = ref("");
const page = ref(1);
const showPagination = ref(true)
const errorGet = ref(false)

const responseCompanies = ref({});
const numberCompanies = ref(0);
const getCompanies = async (params) => {
    try {
        responseCompanies.value = await companies.getCompanies(params);
        numberCompanies.value = responseCompanies.value.companies_count;
    } catch (error) {
        errorGet.value = true
        console.log(error);
    }
};

const filtration = (val) => {
    if (val === activeFilter.value) return;
    activeFilter.value = val;
    search.value = "";
    page.value = 1;

    if (val === "all") {
        getCompanies();
    } else {
        getCompanies({has_contract: val});
    }
};

const companySearch = () => {
    if (search.value.length < 3) {
        const params = {
            search: ''
        }

        if (activeFilter.value !== 'all') {
            params.has_contract = activeFilter.value
        }

        getCompanies(params);
        return
    }
    page.value = 1;

    const params = {
        search: search.value
    }

    if (activeFilter.value !== 'all') {
        params.has_contract = activeFilter.value
    }

    getCompanies(params);
};

const clearSearch = () => {
    search.value = "";
    page.value = 1;

    getCompanies(activeFilter.value === 'all' ? {} : {has_contract: activeFilter.value});
};

const loadMore = async (num) => {
    showPagination.value = false
    page.value = num ? num : page.value + 1;

    const params = {
        search: search.value ? search.value : "",
        page: page.value,
    };

    if (activeFilter.value !== "all") {
        params.has_contract = activeFilter.value;
    }

    try {
        const response = await companies.getCompanies(params);
        if (num) responseCompanies.value.companies = [];
        responseCompanies.value.companies.push(...response.companies);
        showPagination.value = true
    } catch (error) {
        errorGet.value = true
        console.log(error);
    }
};

const pagination = (val) => loadMore(val);

onMounted(getCompanies);
</script>

<style scoped lang="scss">
.companies {
    padding-bottom: 57px;

    &__header {
        margin-top: 24px;
    }

    &__row {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
    }

    &__search {
        width: 437px;

        &::v-deep(.v-input__input) {
            &::placeholder {
                color: $dark-blue;
                opacity: 1;
            }
        }
    }

    &__search-clear {
        cursor: pointer;
    }

    &__content {
        min-height: calc(100vh - 143px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__companies {
        margin-top: 8px;
        margin-left: -32px;
        display: flex;
        flex-wrap: wrap;
    }

    &__company {
        width: calc(33.33% - 32px);
        margin-top: 24px;
        margin-left: 32px;
    }

    &__no-content {
        width: 100%;
        height: calc(100vh - 143px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__pagination {
        position: relative;
        width: 100%;
        height: 48px;
        margin-top: 32px;
        display: flex;
        justify-content: center;
    }

    &__pagination-btn {
        width: 156px;
        padding: 0 10px;
        font-weight: 600;
        border-radius: 8px;

        &:active {
            background-color: #E5EEF8 !important;
            color: #1447AF !important;
        }
    }

    &__pagination-pag {
        position: absolute;
        top: 0;
        right: 0;
    }
}
</style>
